import React from "react";
import './TopNav.css';
import { ReactComponent as Dottttedd } from '../dotted.svg';

const TopNav = ()=>{

    const reloader = ()=> window.location.reload();

    return(<header>
        <nav className="navigatorr top_nav">

            <h2 onClick={reloader} style={{
                fontSize:'19px',marginLeft:'5em', cursor:'pointer',
            }}>DOCUMENTS</h2>

            {/* <img 
                className="dotted"
                alt="dotted"
                src={Dottttedd}
            /> */}

            <Dottttedd className="dott" onClick={reloader}/>


    

        </nav>


        <section className="shrd_dnld"><p className="sh">Shared</p><p className="dla" style={{cursor: 'pointer'}}>DOWNLOAD ALL</p></section>

        <section className="fn_con" style={{fontSize: '15px'}}>

            <p className="sh">Files</p>

            <p className="dla">Name 
            &#160;
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z"></path></svg> </p>
        </section>

    </header>)
};

export default TopNav;